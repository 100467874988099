
import { createContext } from "react";
import { useState } from "react";

export const appContext = createContext()

function ContextProvider({ children }) {
    const [ImageUploaded, setImageUploaded] = useState();
    const [Score, setScore] = useState();
    const [ImageURL, setImageURL] = useState();
    const [IDImage, setIDImage] = useState();
    const [IsScan, setIsScan] = useState(false);
    const [AllImageData, setAllImageData] = useState([]);

    return <appContext.Provider value={{
        value: {
            ImageUploaded,
            Score,
            ImageURL,
            IDImage,
            IsScan,
            AllImageData
        },
        setImageUploaded,
        setScore,
        setImageURL,
        setIDImage,
        setIsScan,
        setAllImageData
    }}>
        {children}
    </appContext.Provider>
}

export default ContextProvider