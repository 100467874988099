import Logo from '../assets/logo.png';
// import { useWeb3Modal } from "@web3modal/react";
// import { disconnect } from '@wagmi/core';
// import { useAccount } from "wagmi";
import {
    useAccount,
    useConnect,
    useDisconnect
} from "wagmi";

function Header() {
    // const { open } = useWeb3Modal();
    // const { isConnected } = useAccount();
    const { connect, connectors, activeConnector } = useConnect();
    const { disconnect } = useDisconnect();
    const { data: accountData } = useAccount();

    const disconnectWallet = async () => {
        disconnect();
    };

    const connectWallet = () => {
        connect({ connector: connectors[0] });
    };

    return (
        <div>
            <header className="lg:py-4">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <nav className="bg-white/5 backdrop-blur-sm border border-white relative flex items-center justify-between h-16 rounded-xl lg:h-16 lg:px-5 px-2 lg:mt-0">
                        <div className="w-full">
                            <a href="/#" title="" className="flex">
                                <img className="w-auto h-6 lg:h-8" src={Logo} alt="" />
                            </a>
                        </div>

                        <div className="w-full text-end">
                            {!activeConnector ?
                                <button onClick={connectWallet} className="bg-second text-white py-2 px-4 rounded-lg">Connect Wallet</button>
                                :
                                <button onClick={disconnectWallet} className="bg-second text-white py-2 px-4 rounded-lg">Disconnect</button>
                            }
                        </div>

                        {/* <button onClick={() => {
                            if (navShow) {
                                setNavShow(false);
                            } else {
                                setNavShow(true);
                            }
                        }} type="button" className="inline-flex p-2 ml-5 text-black transition-all duration-200 rounded-md lg:hidden focus:bg-gray-100 hover:bg-gray-100">
                            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                            </svg>
                        </button> */}

                        {/* <div className="hidden ml-10 lg:flex lg:items-center lg:mr-auto lg:space-x-10">

                            <Link to="/" title="" className="text-base font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600">
                                Home
                            </Link>

                        </div> */}

                        {/* <div className="hidden lg:flex lg:items-center lg:space-x-10">

                            {!connected && (
                                <ConnectButton label='Connect' className="bg-black text-base font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600" />
                            )}
                            {connected && (
                                <>
                                    <button onClick={() => {
                                        setApplyModal(true);
                                    }} className="text-base font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> Apply </button>
                                    <ConnectButton />
                                </>
                            )}
                        </div> */}
                    </nav>

                    {/* {navShow &&
                        <nav className="rounded-md flex flex-col py-4 space-y-2 lg:hidden bg-white px-4 text-center">
                            <Link to="/" title="" className="py-2 text-base font-medium text-black transition-all duration-200 focus:text-blue-600"> Home </Link>

                            <Link to="/upcoming" title="" className="py-2 text-base font-medium text-black transition-all duration-200 focus:text-blue-600"> Upcoming </Link>

                            <Link to="/#" title="" className="relative py-2 text-base font-medium text-black transition-all duration-200 focus:text-blue-600"> Analytics
                                <label className='soon-label-mobile'>soon</label>
                            </Link>

                            <Link to="/create-avatar" title="" className="py-2 text-base font-medium text-black transition-all duration-200 focus:text-blue-600"> Create Avatar </Link>
                            <div className='self-center'>
                                <div className="lg:flex lg:items-center lg:space-x-10">
                                    {!connected && (
                                        <ConnectButton label='Connect' className="bg-black text-base font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600" />
                                    )}
                                    {connected && (
                                        <>
                                            <button onClick={() => {
                                                setApplyModal(true);
                                            }} className="mb-2 text-base font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> Apply </button>
                                            <ConnectButton />
                                        </>
                                    )}
                                </div>
                            </div>
                        </nav>
                    } */}
                </div>
            </header>
        </div>
    );
}

export default Header;