import foxLogo from '../assets/foxlogo512.png';

function About() {

    return (
        <div className='relative'>
            <div className='absolute rounded-full bg-second blur-ellipse6'></div>
            <div className="max-w-5xl mx-auto my-20">
                <div className="grid px-4 md:px-6 lg:px-0 md:grid-cols-2 gap-4 mt-10">
                    <div className='mx-auto'>
                        <img className='mx-auto rounded-xl w-full lg:w-[75%]' src={foxLogo} alt='' />
                    </div>
                    <div className='self-center'>
                        <h1 className="text-white text-start font-black text-2xl lg:text-5xl 2xl:text-6xl font-TT">About 304Fox</h1>
                        <p className='text-white text-base text-start mt-5'>Meet 304Fox, your trusted ally in navigating the complex world of online dating with confidence and ease. 304Fox guides you through the digital landscape with the wisdom of onchain crowds. $304Fox isn't just a memecoin; it's a promise of safer, more authentic connections in the digital age, empowering individuals to preemptively identify red flags with the mission of restoring healthy relationships and the nuclear family as the cornerstone of a flourishing network state. Join 304Fox on this adventure, where trust is just a scan away.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;