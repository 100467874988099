import tokenomics2 from '../assets/tokenomics2.png';

function Tokenomics() {

    return (
        <div className='relative'>
            <div className='absolute rounded-full bg-second blur-ellipse7'></div>
            <div className="max-w-6xl mx-auto py-10 lg:py-20">
                <h1 className="text-white text-center font-black text-2xl lg:text-5xl 2xl:text-6xl font-TT">Tokenomics</h1>
                <div className="grid px-4 md:px-6 lg:px-0 lg:grid-cols-2 gap-6 mt-10">
                    <div className='mx-auto'>
                        <img src={tokenomics2} alt='' />
                    </div>
                    <div className='self-center p-0 lg:p-10'>
                        <div className='bg-white/5 backdrop-blur-md rounded-xl border p-5'>
                            <h2 className='font-TT font-bold text-2xl text-white'>Tokenomics</h2>
                            {/* <p className='text-white mt-2'>The Linear Vest Will Span Across 4 days, 25% After Token Launch And Then 25% At A Scheduled Time Each Subsequent Day. Soft-Cap 100 Eth-Hard Cap: 125 ETH, - There Is a Maximum Investable Amount Of 1 ETH Per Wallet. All Wallets That Have Private Presale Arrangements Must Use The Wallet Address And Exact Amount They Started To Invest.</p> */}
                            <ul className='text-white mt-2'>
                                <li> 69.420T $tokens</li>
                                <li> No Presale</li>
                                <li> No PermanentTaxes</li>
                                <li> No Allocations or hidden wallets</li>
                                <li> No BS</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tokenomics;