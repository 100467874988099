import follow1 from '../assets/icon/follow-1.png';
import follow2 from '../assets/icon/follow-2.png';
import follow3 from '../assets/icon/follow-3.png';
import follow4 from '../assets/icon/follow-4.png';

function SocmedFooter() {

    const contentFollow = [
        {
            image: follow1,
            content: 'Twitter',
            link: 'https://twitter.com/304fax'
        },
        {
            image: follow2,
            content: 'Coin Gecko',
            link: ''
        },
        {
            image: follow3,
            content: 'Telegram',
            link: 'https://t.me/+bWV-rSddSJdmODUx'
        },
        {
            image: follow4,
            content: 'Etherscan',
            link: ''
        },
    ];

    return (
        <footer className='py-5 bg-white/5 backdrop-blur-sm border-t text-center text-white text-sm'>
            <div className="grid md:grid-cols-2 mx-10">
                <div className="flex justify-center md:justify-start space-x-8 text-base items-center">
                    <a href="/#privacy">Privacy</a>
                    <a href="/#about">About</a>
                </div>
                <div className='flex space-x-4 justify-center md:justify-end items-center mt-5 md:mt-0'>
                    {contentFollow.map((item, index) => (
                        <a href={item.link} target='_blank' rel="noreferrer">
                            <div className={`${item.image === follow4 ? 'p-0.5' : 'p-2'} bg-white rounded-full grid max-w-fit mx-auto`}>
                                <img className={`${item.image === follow4 ? 'w-8 h-8' : 'w-5 h-5'} mx-auto`} src={item.image} alt='' />
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </footer>
    );
}

export default SocmedFooter;