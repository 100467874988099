import planned from '../assets/planned.png';

function Plan() {

    return (
        <div className='relative'>
            <div className='absolute rounded-full bg-second blur-ellipse6'></div>
            <div className="max-w-5xl mx-auto my-20">
                <div className="grid px-4 md:px-6 lg:px-0 md:grid-cols-2 gap-6 mt-10">
                    <div className='self-center'>
                        <h1 className="text-white text-start font-black text-2xl lg:text-5xl 2xl:text-6xl font-TT">Roadmap</h1>
                        {/* <p className='text-white text-base text-start mt-5'>The community may add another level to the initial release. This can include an api, matchmaking web app, extended reports with more data, governance or any initiative the community supports.</p> */}
                        <ul className='text-white text-base text-start mt-5'>
                            <li>Phase 1: 304fox Meme</li>
                            <li>Phase 2: 304fax Meme dapp</li>
                            <li>Phase 3: Memetic Takeover</li>
                        </ul>

                        <button href="#" className='bg-second px-6 py-2 mt-8 text-white rounded-lg'>Read More</button>
                    </div>
                    <div className='mx-auto'>
                        <img src={planned} alt='' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Plan;