import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// import { 
//   // EthereumClient, 
//   w3mConnectors, 
//   w3mProvider 
// } from '@web3modal/ethereum'
// import { Web3Modal } from '@web3modal/react'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
// import { arbitrum, mainnet, polygon, bsc } from 'wagmi/chains'
//import ContextProvider from "./context";
import ContextProvider from "./context/index";
import { PolybaseProvider } from "@polybase/react";
import { Polybase } from "@polybase/client";
import { connectors } from './connectors';
// import { PrivyProvider } from '@privy-io/react-auth';

const polybase = new Polybase({
  defaultNamespace: process.env.REACT_APP_NAMESPACE_PB,
});

// const chains = [arbitrum, mainnet, polygon, bsc]
// const projectId = process.env.REACT_APP_WC_ID

// const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createClient({
  autoConnect: true,
  // connectors: w3mConnectors({ projectId, chains }),
  connectors
})
// const ethereumClient = new EthereumClient(wagmiConfig, chains)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <WagmiConfig client={wagmiConfig}>
        {/* <PrivyProvider
          appId={process.env.REACT_APP_PRIVY_APP_ID}
          onSuccess={(user) => console.log(`User ${user.id} logged in!`)}
          config={{
            appearance: {
              // This configures your login modal to show wallet login options above other options.
              showWalletLoginFirst: true,
            },
            loginMethods: ['wallet', 'sms'],
            appearance: {
              theme: 'dark',
              accentColor: '#1f4a89',
              logo: '/logo.png',
            },
          }}
        > */}
          <PolybaseProvider polybase={polybase}>
            <ContextProvider>
              <App />
            </ContextProvider>
          </PolybaseProvider>
        {/* </PrivyProvider> */}
      </WagmiConfig>

      {/* <Web3Modal projectId={projectId} ethereumClient={ethereumClient} /> */}
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
