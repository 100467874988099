import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import works1 from '../assets/icon/works-1.png';
import works2 from '../assets/icon/works-2.png';
import works3 from '../assets/icon/works-3.png';
import works4 from '../assets/icon/works-4.png';
import line from '../assets/line2.png';

function How() {
    const playerRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayButtonClick = () => {
        // Use the playerRef to access the ReactPlayer methods
        if (playerRef.current) {
            playerRef.current.getInternalPlayer().play();
            setIsPlaying(true);
        }
    };

    const handleVideoEnded = () => {
        setIsPlaying(false);
    };

    const contentWorks = [
        {
            image: works1,
            content: 'Scan a High Quality Photo or Screenshot From Dating App'
        },
        {
            image: works2,
            content: 'The App sends an AI-generated report from a crowd-sourced, decentralized zero-knowledge database'
        },
        {
            image: works3,
            content: 'Discover Crucial Information That Can Protect You, Avoid 🚩’s, or Have That Difficult Conversation'
        },
        {
            image: works4,
            content: 'Earn Tokens For Supplying Data And Rev Share'
        },
    ];

    return (
        <div className='relative'>
            <div className='absolute rounded-full bg-second blur-ellipse5'></div>
            <div className="max-w-6xl mx-auto my-14">
                <h1 className="text-white text-center font-black text-2xl lg:text-5xl 2xl:text-6xl font-TT">How It Works</h1>
                <div className="grid px-4 md:px-6 lg:px-0 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-10">
                    {contentWorks.map((item, index) => (
                        <div className="bg-white/5 backdrop-blur-sm rounded-xl border grid py-5 px-3">
                            <div>
                                <div className='bg-white rounded-full max-w-fit mx-auto'>
                                    <img className='p-3' src={item.image} alt='' />
                                </div>
                            </div>
                            <div>
                                <p className='text-white text-center text-base pt-5'>{item.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='relative mt-10 px-4'>
                    {!isPlaying && (
                        <button className="absolute play-button bg-white/30 backdrop-blur-sm p-3 rounded-full" onClick={handlePlayButtonClick}>
                            <div className="bg-second p-3 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 0 384 512" fill='white'><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" /></svg>
                            </div>
                        </button>
                    )}
                    <div className='p-3 md:p-10 lg:p-20 flex justify-center bg-white/5 backdrop-blur-sm rounded-xl bg-white w-full md:max-w-fit md:mx-auto'>
                        <ReactPlayer
                            className='!w-[300px] !h-[220px] md:!w-[940px] md:!h-[540px]'
                            ref={playerRef}
                            url="https://vimeo.com/883137325"
                            controls={false}
                            onEnded={handleVideoEnded}
                        />
                    </div>
                </div>
            </div>
            <img className="w-6/12 lg:w-auto ml-auto mt-20 line-position2" src={line} alt="" />
        </div>
    );
}

export default How;