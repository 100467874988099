import { useState, useRef, useEffect, useContext } from "react";
import { appContext } from "../context";
import Header from "../components/header";
import link from '../assets/upload-bg.png';
import line1 from '../assets/line1.png';
import line2 from '../assets/line2.png';
import arrowUp from '../assets/icon/arrow-up.png';
import arrowDown from '../assets/icon/arrow-down2.png';
import upload from '../assets/icon/upload.png'
import SocmedFooter from "../components/socmed-footer";
import * as faceapi from "face-api.js"
import { useNavigate } from "react-router-dom";
import { usePolybase } from "@polybase/react";
import { create as ipfsHttpClient } from "ipfs-http-client";
// import { useAccount } from "wagmi";
import { readContract } from '@wagmi/core'
import ABI from '../assets/ABI_Token.json';
// import { usePrivy } from '@privy-io/react-auth';
import {
    useAccount,
    useConnect,
} from "wagmi";

const projectId = process.env.REACT_APP_INFURA_ID;
const projectSecretKey = process.env.REACT_APP_INFURA_SECRET;
const authorization = "Basic " + btoa(projectId + ":" + projectSecretKey);

function Upload() {
    const navigate = useNavigate();
    const generateUniqueId = require('generate-unique-id');
    const [UploadedImage, setUploadedImage] = useState();
    const [ImageSelected, setImageSelected] = useState(null);
    const [SelectedItem, setSelectedItem] = useState(null);
    const [width, setWidth] = useState(0);
    const [Loading, setLoading] = useState(false);
    const myImage = useRef(null);
    const myCanvas = useRef(null);
    const [ready, setReady] = useState(false);
    const [URLImage, setURLImage] = useState(null);
    const [Option1, setOption1] = useState(false);
    const [Option2, setOption2] = useState(false);
    const [Option3, setOption3] = useState(false);
    const polybase = usePolybase();
    const {
        setImageUploaded,
        setScore,
        setImageURL,
        setIDImage,
        setIsScan
    } = useContext(appContext);
    const [Descriptor, setDescriptor] = useState(null);
    const ipfs = ipfsHttpClient({
        url: "https://ipfs.infura.io:5001/api/v0",
        headers: {
            authorization,
        },
    });
    const { activeConnector } = useConnect();
    const { data: accountData } = useAccount();
    // const { address, isConnected } = useAccount();
    // const { ready: wallerReady, authenticated, user } = usePrivy()
    const allowedDomains = ["onlyfans.com", "seeking.com"];

    const handleImageChange = (e) => {
        const file = e.target.files[0]; // Get the first selected file
        setUploadedImage(file);
        if (file && file.type.startsWith('image/')) {
            // Check if the selected file is an image
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSelected(e.target.result); // Set the selected image as a data URL
            };
            reader.readAsDataURL(file);
        } else {
            // select image
        }
    };

    const contentFAQ = [
        {
            q: 'What URL’s Are Allowed?',
            a: 'The protocol accepts URLs that lead to genuine, verifiable content. You can submit links from a list of approved sources, ensuring the content is legally shareable (for example, if archive.org is one of the whitelisted domains). Check the full list of approved sources to make sure your submission meets guidelines.'
        },
        {
            q: 'When Do I Recieve Tokens?',
            a: 'TBA: Tokens *may* be distributed as a reward for contributing to the protocol. Once your uploaded photo and its associated link are verified and approved, tokens *may* be credited to your account typically within 48 hours. Keep track of your submissions in your dashboard to see the status of your rewards.'
        },
        {
            q: 'What Are The Categories?',
            a: 'We embrace a wide range of categories to enrich our training set. From film and video to dating apps, your photos help us build a diverse collection. However, we do not accept any content that is offensive, infringing, or in violation of intellectual property rights. For a detailed list of acceptable categories, please refer to our submission guidelines.'
        },
    ];

    const validateURLImage = (URLImage) => {

        for (const domain of allowedDomains) {
            if (URLImage.includes(domain)) {
                return true;
            }
        }

        return false;
    };

    const validateDuplicate = async (descriptor, imageUrl) => {
        const collectionReference = polybase.collection("dbFax");
        const records = await collectionReference.where("Descriptor", "==", descriptor).get();

        const { data } = records;
        for (let i = 0; i < data.length; i++) {
            if (data[i].data.URL === imageUrl) {
                return false;
            }
        }
        return true;
    };

    const imageToBytes = async (imageFile) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = function () {
                const bytes = new Uint8Array(reader.result);
                resolve(bytes);
            };

            reader.onerror = function (error) {
                reject(error);
            };

            reader.readAsArrayBuffer(imageFile);
        });
    };

    // const bytesToImage = async (bytes) => {
    //     const blob = new Blob([bytes], { type: 'image/jpeg' });
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();

    //         reader.onload = function () {
    //             const dataUrl = reader.result;
    //             resolve(dataUrl);
    //         };

    //         reader.onerror = function (error) {
    //             reject(error);
    //         };

    //         reader.readAsDataURL(blob);
    //     });
    // };

    const runUpload = async () => {
        const contractABI = ABI;

        const data = await readContract({
            address: process.env.REACT_APP_TOKEN_ADDRESS,
            abi: contractABI,
            functionName: 'balanceOf',
            args: [accountData?.address],
            chainId: Number(process.env.REACT_APP_TOKEN_CHAIN),
        });

        if (Number(data) === 0) {
            alert('Only users holding WETH tokens!');
            // return;
        }

        const isValidURL = validateURLImage(URLImage);

        if (!isValidURL) {
            alert('The URLs you entered are not on the whitelist!');
            return;
        }

        try {
            const results = await faceapi
                .detectAllFaces(myImage.current, new faceapi.TinyFaceDetectorOptions())
                .withFaceLandmarks()
                .withFaceDescriptors()
                .withAgeAndGender()

            faceapi.matchDimensions(myCanvas.current, myImage.current)

            const resizedResults = faceapi.resizeResults(results, myImage.current)

            faceapi.draw.drawDetections(myCanvas.current, resizedResults)
            faceapi.draw.drawFaceLandmarks(myCanvas.current, resizedResults);

            if (resizedResults.length === 0) {
                alert('Sorry, your image was not detected for checking!');
                return;
            }

            // if (resizedResults[0]?.age < 18) {
            //     alert('You can only upload images that are detected as 18 years old and above!');
            //     return;
            // }

            resizedResults.forEach(result => {
                const {
                    age
                } = result
                new faceapi.draw.DrawTextField(
                    [
                        `${faceapi.utils.round(age, 0)} yo`
                    ],
                    result.detection.box.topRight
                ).draw(myCanvas.current)
            })

            const regularArray = Array.from(resizedResults[0]?.descriptor);
            const arrayAsString = JSON.stringify(regularArray);
            const dataDuplicate = await validateDuplicate(arrayAsString, URLImage);

            if (!dataDuplicate) {
                alert('The URL must not be the same as one that has been previously uploaded!');
                return;
            }

            setDescriptor(arrayAsString);
            setScore(Math.floor(resizedResults[0]?.detection?.score * 100) / 100);

            setLoading(true);
            return true;
        } catch (error) {
            console.log(error);
            setLoading(false);
            return false;
        }
    }

    const uploadData = async () => {
        // here will be the stored function for database
        await createData();
    };

    const createData = async () => {
        const result = await ipfs.add(UploadedImage);
        const id = generateUniqueId({
            length: 25
        });
        const dateNow = new Date();
        const numericDate = dateNow.getTime();
        const collectionReference = polybase.collection("dbFax");

        const bytes = await imageToBytes(UploadedImage);
        // const dataUrl = await bytesToImage(bytes);

        await collectionReference.create([
            id, // id
            result.path, // image id
            `https://skywalker.infura-ipfs.io/ipfs/${result.path}`, // image url
            bytes, // image data
            Descriptor, // image descriptor
            URLImage, // url image
            Option1, // option 1
            Option2, // option 2
            Option3, // option 3
            dateNow.toISOString(), // created at
            numericDate, // numdate
        ]);
        setImageUploaded(`https://skywalker.infura-ipfs.io/ipfs/${result.path}`);
        setImageURL(URLImage);
        setIDImage(id);
        setIsScan(false);

        alert('Upload succeed!');
        navigate('/report');
    };

    useEffect(() => {
        if (!activeConnector) {
            return;
        }

        // if (!authenticated) {
        //     alert('Please connect your wallet first!')
        //     return;
        // }

        async function checkToken() {
            const contractABI = ABI;
            const data = await readContract({
                address: process.env.REACT_APP_TOKEN_ADDRESS,
                abi: contractABI,
                functionName: 'balanceOf',
                args: [accountData?.address],
                chainId: Number(process.env.REACT_APP_TOKEN_CHAIN),
            });
            if (Number(data) === 0) {
                alert('Only users holding WETH tokens!');
                // navigate('/');
                // return;
            }
        }
        // checkToken();

        Promise.all([
            faceapi.nets.ageGenderNet.loadFromUri('/models'),
            faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
            faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
            faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
        ]).then(() => {
            setReady(true);
        }).catch((err) => {
            console.log(err);
        })

    }, [activeConnector])

    useEffect(() => {
        if (!Loading) {
            return;
        }

        let currentWidth = 0;
        const totalSeconds = 60;
        const intervalDelay = (totalSeconds * 1000) / 100;

        const interval = setInterval(() => {
            if (currentWidth < 100) {
                currentWidth += 1;
                setWidth(currentWidth);
            } else {
                clearInterval(interval);
                uploadData();
            }
        }, intervalDelay);

        return () => clearInterval(interval);
    }, [Loading]);

    return (
        <div className='relative z-50 bg-main pt-4 h-screen overflow-x-hidden'>
            <div className='absolute rounded-full bg-second blur-ellipse1'></div>
            <div className='absolute rounded-full bg-second blur-ellipse2'></div>
            <Header />
            <img className="wave-position w-screen" style={{ zIndex: '-1' }} src={link} alt="" />
            <div className="max-w-6xl mx-auto">
                <div className="relative text-center mt-10 md:mt-20 px-4 pb-4">
                    <h1 className="text-white max-w-5xl mx-auto font-bold text-3xl lg:text-5xl 2xl:text-6xl font-TT">Earn Tokens by Sharing Your Photos to Training Set.</h1>
                    <p className="text-xl max-w-3xl mx-auto mt-5 text-white">If you're holding staked tokens, here's your chance to contribute and earn rewards.</p>
                </div>
                <div className="text-center pb-4 px-4 md:px-0">
                    {ImageSelected === null ?
                        <div className="bg-white/5 backdrop-blur-sm border max-w-lg mx-auto h-80 rounded-xl flex justify-center items-center">
                            {ready ?
                                <div className="grid">
                                    <label for="dropzone-file" className="cursor-pointer border px-6 py-2 rounded-lg text-white flex items-center mx-auto"><img className="mr-4" src={upload} alt="" />Upload a Photo</label>
                                    <input onChange={handleImageChange} id="dropzone-file" type="file" class="hidden" accept="image/*" />
                                </div>
                                :
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            }
                        </div>
                        :
                        <div className="relative">
                            {!Loading &&
                                <button onClick={() => { setImageSelected(null) }} className="z-50 border mt-1 bg-second/20 backdrop-blur-sm rounded-lg px-4 py-2 absolute -bottom-5 right-1/2 transform translate-x-1/2 p-5 text-white">Remove</button>
                            }
                            <img ref={myImage} className="border h-80 w-auto max-w-lg mx-auto rounded-xl object-cover" src={ImageSelected} alt="" />
                            <canvas className="absolute top-0 h-80 w-auto max-w-lg mx-auto object-cover canvas-fr" ref={myCanvas}></canvas>
                        </div>
                    }
                </div>
                {!Loading &&
                    <>
                        <div className="border rounded-lg flex items-center px-4 py-2 max-w-xl mx-4 lg:mx-auto mt-8">
                            <p className="text-white min-w-fit pr-4">Enter URL:</p>
                            <input value={URLImage} onChange={(e) => { setURLImage(e.target.value) }} className="w-full bg-transparent my-2 focus:outline-none text-white border-b" />
                            <p className="text-white pl-4">(Required)</p>
                        </div>
                        <div className="text-center mt-3 px-4 lg:px-0">
                            <p className="text-white">(Only Whitelisted URL Allowed ie: archive.org)</p>
                        </div>
                        <p className="text-white my-5 text-center">OR</p>
                        <div className="flex space-x-10 justify-center">
                            <div class="flex items-center">
                                <input id="sugaring" type="checkbox" checked={Option1} onChange={() => setOption1(!Option1)} class="w-6 h-6 bg-transparent border rounded focus:ring-0" />
                                <label for="sugaring" class="ml-2 text-sm font-medium text-white">Sugaring</label>
                            </div>
                            <div class="flex items-center">
                                <input id="adult-video" type="checkbox" checked={Option2} onChange={() => setOption2(!Option2)} value="" class="w-6 h-6 bg-transparent border rounded focus:ring-0" />
                                <label for="adult-video" class="ml-2 text-sm font-medium text-white">Adult Video</label>
                            </div>
                            <div class="flex items-center">
                                <input id="304" type="checkbox" checked={Option3} onChange={() => setOption3(!Option3)} class="w-6 h-6 bg-transparent border rounded focus:ring-0" />
                                <label for="304" class="ml-2 text-sm font-medium text-white">304</label>
                            </div>
                        </div>
                    </>
                }

                {!Loading ?
                    <>
                        {ImageSelected &&
                            <>
                                {activeConnector ?
                                    <div className="text-center mt-5">
                                        <button onClick={() => { 
                                            runUpload()   
                                        }} className="px-6 py-2 rounded-lg bg-second hover:bg-second/50 duration-200 transition-all text-white text-lg">Upload</button>
                                    </div>
                                    :
                                    <div className="text-center mt-5">
                                        <button onClick={() => { alert('Connect your wallet to interact!') }} className="px-6 py-2 rounded-lg bg-second hover:bg-second/50 duration-200 transition-all text-gray-400 text-lg">Upload</button>
                                    </div>
                                }
                            </>
                        }
                    </>
                    :
                    <div className="text-center mt-5">
                        <p className="text-white">Checking...</p>
                        <div class="w-full max-w-xl text-white mx-auto bg-gray-200 rounded-full h-4">
                            <div class="bg-second h-4 text-xs rounded-full text-center" style={{ width: `${width}%` }}>{width}%</div>
                        </div>
                    </div>
                }
            </div>
            <div className="mt-32 mb-20 px-4 lg:px-0 relative">
                <div className='absolute rounded-full bg-second blur-ellipse3'></div>
                <div className='absolute rounded-full bg-second blur-ellipse4'></div>
                <h1 className="text-white text-center font-black text-2xl lg:text-5xl 2xl:text-6xl font-TT">FAQ</h1>
                <div className="mt-14">
                    {contentFAQ.map((item, index) => (
                        <div className="text-white grid max-w-4xl mx-auto mb-4">
                            <div className="bg-white/5 backdrop-blur-sm rounded-lg w-full py-4 px-6 border grid">
                                <button onClick={() => {
                                    if (SelectedItem === null) {
                                        setSelectedItem(index)
                                    } else {
                                        setSelectedItem(null)
                                    }
                                }} className="text-start flex items-center">
                                    <span>{item.q}</span>
                                    {SelectedItem === index ?
                                        <img className="ml-auto" src={arrowUp} alt="" />
                                        :
                                        <img className="ml-auto" src={arrowDown} alt="" />
                                    }
                                </button>
                            </div>
                            {SelectedItem === index &&
                                <p className="mt-4">{item.a}</p>
                            }
                        </div>
                    ))}
                </div>
            </div>
            <img className='ml-auto my-5' src={line2} alt='' />
            <img className='mb-3 mt-5 mb-10' src={line1} alt='' />
            <SocmedFooter />
        </div>
    );
}

export default Upload;
