import { useState, useEffect, useRef, useContext } from "react";
import Header from "../components/header";
import link from '../assets/upload-bg.png';
import line1 from '../assets/line1.png';
import line2 from '../assets/line2.png';
import upload from '../assets/icon/upload.png'
import SocmedFooter from "../components/socmed-footer";
import * as faceapi from "face-api.js"
import { useNavigate } from "react-router-dom";
import { usePolybase } from "@polybase/react";
import { appContext } from "../context";
import moment from "moment";
import * as ethers from "ethers";
import ABI from '../assets/ABI_304fax.json';
import {
    useConnect,
} from "wagmi";

function Scan() {
    const { activeConnector } = useConnect();
    const navigate = useNavigate();
    const [ImageSelected, setImageSelected] = useState(null);
    const [ready, setReady] = useState(false);
    const [IsImageFound, setIsImageFound] = useState(0);
    const [IsPayed, setIsPayed] = useState(false);
    const [IsLoadTx, setIsLoadTx] = useState(false);
    const [IsScanning, setIsScanning] = useState(false);
    const myImage = useRef(null);
    const myCanvas = useRef(null);
    const polybase = usePolybase();
    const {
        setImageUploaded,
        setScore,
        setImageURL,
        setIDImage,
        setIsScan,
        setAllImageData
    } = useContext(appContext);

    const handleImageChange = (e) => {
        const file = e.target.files[0]; // Get the first selected file
        if (file && file.type.startsWith('image/')) {
            // Check if the selected file is an image
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSelected(e.target.result); // Set the selected image as a data URL
            };
            reader.readAsDataURL(file);
        } else {
            // select image
        }
    };

    const getDataImageDescriptor = (responseData) => {
        return Promise.all(
            responseData.map(async (item) => {
                const descriptions = [];
                // const img = await faceapi.fetchImage(item.data.ImageData);
                // const detections = await faceapi
                //     .detectAllFaces(img, new faceapi.TinyFaceDetectorOptions())
                //     .withFaceLandmarks()
                //     .withFaceDescriptors()

                const parsedArray = JSON.parse(item.data.Descriptor);
                const float32ArrayAgain = new Float32Array(parsedArray);
                descriptions.push(float32ArrayAgain);
                return new faceapi.LabeledFaceDescriptors(item.data.ImageID, descriptions);
            })
        );
    };

    const getData = async (e) => {
        const collectionReference = polybase.collection("dbFax");
        // const record = await collectionReference.get();
        const record = await collectionReference.limit(100000).get();
        const { data } = record;
        // console.log(data);
        return data;
    };

    const getLatestCreatedAtDate = (data) => {
        if (!data || data.length === 0) {
            return null;
        }

        let latestDate = moment(data[0]?.data?.CreatedAt);
        let latestData = data[0];

        for (let i = 1; i < data.length; i++) {
            const currentDate = moment(data[i]?.data?.CreatedAt);
            if (currentDate.isAfter(latestDate)) {
                latestDate = currentDate;
                latestData = data[i];
            }
        }
        // console.log("latest33"); console.log(latestData); console.log("latest55");
        return latestData;
    };

    const runUpload = async () => {
        setIsScanning(true);
        const response = await getData();
        const dataDescriptors = await getDataImageDescriptor(response);
        // const faceMatcher = new faceapi.FaceMatcher(dataDescriptors);

        const results = await faceapi
            .detectAllFaces(myImage.current, new faceapi.TinyFaceDetectorOptions())
            .withFaceLandmarks()
            .withFaceDescriptors()

        faceapi.matchDimensions(myCanvas.current, myImage.current)

        const resizedResults = faceapi.resizeResults(results, myImage.current)

        faceapi.draw.drawDetections(myCanvas.current, resizedResults)
        faceapi.draw.drawFaceLandmarks(myCanvas.current, resizedResults);

        if (resizedResults.length === 0) {
            alert('Sorry, your image was not detected for checking!');
            return;
        }

        // let labelName;
        // let score;
        // // let descr;
        // resizedResults.forEach(({ descriptor }) => {
        //     const match = faceMatcher.findBestMatch(descriptor);
        //     // const options = { label }
        //     labelName = match.label;
        //     score = match.distance;
        //     // descr = descriptor;
        //     // const drawBox = new faceapi.draw.DrawBox(detection.box, options)
        //     // drawBox.draw(myCanvas.current)
        // })

        let topImage = [];
        resizedResults.forEach(({ descriptor }) => {
            // Calculate distances from the current face to all faces in the database
            let matches = dataDescriptors.map((labeledDescriptor) => {
                return labeledDescriptor.descriptors.map((dbDescriptor) => {
                    const distance = faceapi.euclideanDistance(descriptor, dbDescriptor);
                    return {
                        label: labeledDescriptor.label,
                        distance: distance
                    };
                });
            }).flat(); // Flatten the array since map returns an array for each labeledDescriptor

            // Sort matches by distance (ascending, so smaller distances are first)
            matches.sort((a, b) => a.distance - b.distance);

            // Select the top 10 matches
            let top10Matches = matches.slice(0, 20);

            // Now `top10Matches` contains the 10 closest matches for the current face
            // You can process these matches as needed. For example, logging them:
            top10Matches.forEach(match => {
                // console.log(`Label: ${match.label}, Distance: ${match.distance}`);
                topImage.push(
                    {
                        label: match.label,
                        distance: match.distance
                    }
                )
            });
        });

        const result = topImage[0].label.search('unknown');
        if (result !== -1) {
            setIsImageFound(2);
            // console.log('this is result if isImageFound is 2 ' + result);
        } else {
            setIsImageFound(1);
            // console.log('this is result if isImageFound is 1 ' + result)
            let dataTopImage = [];
            for (let i = 0; i < topImage.length; i++) {
                const collectionReference = polybase.collection("dbFax");
                const record = await collectionReference
                    .where("ImageID", "==", topImage[i].label)
                    .get();
                const { data } = record;

                dataTopImage.push(
                    {
                        label: topImage[i].label,
                        distance: topImage[i].distance,
                        url: data[0]?.data?.URL
                    }
                )
            }

            const collectionReference = polybase.collection("dbFax");
            const record = await collectionReference
                .where("ImageID", "==", dataTopImage[0].label)
                .get();
            const { data } = record;

            const latestCreatedAtDate = getLatestCreatedAtDate(data);
            setIDImage(latestCreatedAtDate?.data?.id);
            setImageURL(latestCreatedAtDate?.data?.URL);
            setImageUploaded(latestCreatedAtDate?.data?.ImageURL);
            setScore(Math.floor(dataTopImage[0].distance * 100) / 100);
            setAllImageData(dataTopImage);
            setIsScan(true);
            setIsScanning(false);
            navigate('/report');
        }
    }

    function isChainIdSepolia(chainId) {
        const targetChainId = Number(process.env.REACT_APP_CONTRACT_CHAIN);
        const chainIdString = chainId.toString();
        return chainIdString === targetChainId.toString();
    }

    const networkCheck = async (provider) => {
        let status;
        await provider.getNetwork().then((network) => {
            if (isChainIdSepolia(network.chainId)) {
                status = true;
            } else {
                status = false;
            }
        });

        if (status) {
            return true;
        } else {
            return false;
        }
    };

    const pay = async (e) => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

            const isNetwork = await networkCheck(provider);
            if (!isNetwork) {
                console.log(process.env.REACT_APP_CONTRACT_CHAIN_NAME);
                alert(`This action is only for the ${process.env.REACT_APP_CONTRACT_CHAIN_NAME} network.`);
                return;
            }

            const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
            const contractABI = ABI;
            const contract = new ethers.Contract(contractAddress, contractABI, signer);
            setIsLoadTx(true);
            const tx = await contract.payForScan();
            await tx.wait().then(async () => {
                setIsLoadTx(false);
                setIsPayed(true);
            })
        } catch (error) {
            console.log(error);
            setIsLoadTx(false);
        }
    };

    useEffect(() => {
        Promise.all([
            faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
            faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
            faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
        ]).then(() => {
            setReady(true);
        }).catch((err) => {
            console.log(err);
        })

    }, [])

    return (
        <div className='relative z-50 bg-main pt-4 h-screen overflow-x-hidden'>
            <div className='absolute rounded-full bg-second blur-ellipse1'></div>
            <div className='absolute rounded-full bg-second blur-ellipse2'></div>
            <Header />
            {IsLoadTx &&
                <div className="fixed bottom-0 right-0 m-5 z-50">
                    <div id="toast-default" class="flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow" role="alert">
                        <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-black rounded-lg">
                            <div role="status">
                                <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-400 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                            </div>
                        </div>
                        <div class="ml-3 text-sm font-bold">Waiting for transaction...</div>
                    </div>
                </div>
            }
            <img className="wave-position w-screen" style={{ zIndex: '-1' }} src={link} alt="" />
            <div className="max-w-6xl mx-auto">
                <div className="relative text-center mt-10 md:mt-20 px-4 pb-4">
                    <h1 className="text-white font-black text-3xl lg:text-5xl 2xl:text-6xl font-TT">Vet a Potential Match And Dicover Red Flags Early With AI</h1>
                    <p className="text-xl max-w-lg mx-auto mt-5 text-white">Avoid Financial Fraudsters, Catfish Impersonators. & Digital 304s Upload A Photo And Find Out!</p>
                </div>

                <div className="text-center pb-4 px-4 md:px-0">
                    {ImageSelected === null ?
                        <div className="bg-white/5 backdrop-blur-sm border max-w-lg mx-auto h-80 rounded-xl flex justify-center items-center">
                            {ready ?
                                <div className="grid">
                                    <label for="dropzone-file" className="cursor-pointer border px-6 py-2 rounded-lg text-white flex items-center mx-auto"><img className="mr-4" src={upload} alt="" />Upload a Photo</label>
                                    <input onChange={handleImageChange} id="dropzone-file" type="file" class="hidden" accept="image/*" />
                                    <p className="text-white mt-2">2,000 Reports Generated And Counting</p>
                                </div>
                                :
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            }
                        </div>
                        :
                        <div className="relative">
                            {IsImageFound === 0 &&
                                <button onClick={() => { setImageSelected(null) }} className="z-50 border mt-1 bg-second/20 backdrop-blur-sm rounded-lg px-4 py-2 absolute -bottom-5 right-1/2 transform translate-x-1/2 p-5 text-white">Remove</button>
                            }
                            <img ref={myImage} className="border h-80 w-auto max-w-lg mx-auto rounded-xl object-cover" src={ImageSelected} alt="" />
                            <canvas className="absolute top-0 h-80 w-auto max-w-lg mx-auto object-cover canvas-fr" ref={myCanvas}></canvas>
                        </div>
                    }
                </div>
                {
                    IsScanning ?
                        <div className="flex justify-center items-center mt-4" role="status">
                            <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div>
                        :
                        <>
                            {
                                IsImageFound === 2 ?
                                    <div className="grid text-center mt-5">
                                        <p className="text-white">Oops! Your image not found :(</p>
                                        <button onClick={() => { setIsImageFound(0) }} className="max-w-fit mx-auto mt-2 rounded-lg bg-second px-6 py-2 text-sm text-white">Retry</button>
                                    </div>
                                    :
                                    <>
                                        {ImageSelected &&
                                            <div className="text-center mt-5 flex justify-center space-x-2">
                                                <button onClick={() => {
                                                    if (!activeConnector) {
                                                        alert('Please connect your wallet first!')
                                                        return;
                                                    }
                                                    pay()
                                                }} className="px-6 py-2 rounded-lg bg-second hover:bg-second/50 duration-200 transition-all text-white text-lg">Pay</button>
                                                <button onClick={() => {
                                                    // Removed payment option in following 4 lines:
                                                    // if (!IsPayed) {
                                                    //     alert('Please pay 0.01 ETH in advance before scanning the image');
                                                    //     return;
                                                    // }

                                                    runUpload()
                                                }} className={`px-6 py-2 rounded-lg ${IsPayed ? 'bg-second hover:bg-second/50 duration-200 transition-all' : 'bg-second/50'} text-white text-lg`}>Scan</button>
                                            </div>
                                        }
                                    </>
                            }
                        </>
                }
                <div className="mt-3 text-center">
                    <a className="text-white underline" href="https://twitter.com/intent/tweet?text=Hey%20%40304fax%2C%20I%27d%20love%20to%20get%20a%20referral%20code%20for%20a%20free%20scan%21%20%23304fax%20%23hoefax" target="_blank" rel="noreferrer">Request Free Code</a>
                </div>
            </div>
            <img className='ml-auto my-5' src={line2} alt='' />
            <img className='mb-3 mt-5 mb-10' src={line1} alt='' />
            <SocmedFooter />
        </div>
    );
}

export default Scan;