import follow1 from '../assets/icon/follow-1.png';
import follow2 from '../assets/icon/follow-2.png';
import follow3 from '../assets/icon/follow-3.png';
import follow4 from '../assets/icon/follow-4.png';
import line1 from '../assets/line1.png';
import line2 from '../assets/line2.png';

function Follow() {

    const contentFollow = [
        {
            image: follow1,
            url: 'https://twitter.com/304fax',
            content: 'Twitter'
        },
        {
            image: follow2,
            url: 'https://www.coingecko.com/',
            content: 'Coin Gecko'
        },
        {
            image: follow3,
            url: 'https://t.me/+bWV-rSddSJdmODUx',
            content: 'Telegram'
        },
        {
            image: follow4,
            url: '/#',
            content: 'Etherscan'
        },
    ];

    return (
        <div className='relative'>
            <div className='absolute rounded-full bg-second blur-ellipse8'></div>
            <div className="max-w-6xl mx-auto py-10 lg:py-20">
                <div className="rounded-xl border bg-white/5 backdrop-blur-sm mx-6 lg:mx-0">
                    <img className='w-6/12 mb-3 mt-5' src={line1} alt='' />
                    <h1 className="text-white text-center font-black text-2xl lg:text-5xl 2xl:text-6xl font-TT">Follow Us!</h1>
                    <div className="text-center">
                        <div className="grid grid-cols-2 gap-4 space-x-0 md:flex justify-center md:space-x-20 md:gap-0 mt-10">
                            {contentFollow.map((item, index) => (
                                <a href={item.url} target="_blank" rel="noopener noreferrer" key={index}>
                                    <div>
                                        <div className={`${item.image === follow4 ? 'p-1 md:p-3' : 'p-3 md:p-5'} bg-white rounded-full grid max-w-fit mx-auto`}>
                                            <img className={`${item.image === follow4 ? 'w-11 h-11 md:w-14 md:h-14' : 'h-7 w-7 md:w-10 md:h-10'} mx-auto`} src={item.image} alt='' />
                                        </div>
                                        <div className='mt-4'>
                                            <p className='text-white'>{item.content}</p>
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                    <img className='w-6/12 ml-auto my-5' src={line2} alt='' />
                </div>
            </div>
        </div>
    );
}

export default Follow;