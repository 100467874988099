import { configureChains } from "wagmi";
import { mainnet } from 'wagmi/chains'

import { publicProvider } from "wagmi/providers/public";

import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";

// Configure chains for connectors to support
const { chains } = configureChains([mainnet], [
  publicProvider()
]);

// Set up connectors
export const connectors = [
  new CoinbaseWalletConnector({
    chains,
    options: {
      appName: "304FAX",
      connectionPreference: "embedded",
    }
  })
];
