import { Tweet } from 'react-tweet'

function Testimonial() {
    return (
        <div class="min-w-screen min-h-screen flex items-center justify-center py-5">
            <div class="w-full px-5 py-16 md:py-18 text-white">
                <div class="w-full max-w-6xl mx-auto">
                    <div class="text-center max-w-xl mx-auto pb-4">
                        <h1 className="text-white text-center font-black text-2xl lg:text-5xl 2xl:text-6xl font-TT mb-4">What people are saying</h1>
                    </div>
                    <div class="-mx-3 md:flex items-start">
                        <div class="px-3 md:w-1/3">
                            <div data-theme="dark">
                                <Tweet id="1715872952258847051" />
                            </div>
                            <div data-theme="dark">
                                <Tweet id="1695219575259078731" />
                            </div>
                        </div>
                        <div class="px-3 md:w-1/3">
                            <div data-theme="dark">
                                <Tweet id="1719739427113627910" />
                            </div>
                            <div data-theme="dark">
                                <Tweet id="1692685507010920471" />
                            </div>
                        </div>
                        <div class="px-3 md:w-1/3">
                            <div data-theme="dark">
                                <Tweet id="1703517961594548340" />
                            </div>
                            <div data-theme="dark">
                                <Tweet id="1722379925129433121" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonial;
