import { useState, useContext, useEffect } from "react";
import { appContext } from "../context";
import Header from "../components/header";
import r1 from '../assets/icon/r-1.png';
import r2 from '../assets/icon/r-2.png';
import r3 from '../assets/icon/r-3.png';
import r4 from '../assets/icon/r-4.png';
import dd1 from '../assets/SugarBabyPopula.jpeg';
import dd2 from '../assets/Onlyfans Creat.jpeg';
import arrowDown from '../assets/icon/arrow-down2.png';
import arrowUp from '../assets/icon/arrow-up.png';
import wave from '../assets/wave.png';
import line1 from '../assets/line1.png';
import line2 from '../assets/line2.png';
import SocmedFooter from "../components/socmed-footer";
import { usePolybase } from "@polybase/react";
import * as ethers from "ethers";
import ABI from '../assets/ABI_304fax.json';

function Report() {
    const {
        setImageUploaded,
        setScore,
        setImageURL,
        setIDImage,
        value
    } = useContext(appContext);
    const {
        ImageUploaded,
        Score,
        ImageURL,
        IDImage,
        IsScan,
        AllImageData
    } = value;
    const [SelectedItem, setSelectedItem] = useState(null);
    const [DataImage, setDataImage] = useState(null);
    const [IsLoadTx, setIsLoadTx] = useState(false); //
    const polybase = usePolybase();

    const contentReport = [
        {
            image: r1,
            content: 'I’m Not Saying, They’re a Gold Digger, But....',
            imgContent: dd1,
            explanation: 'According to the U.S. Census, there are roughly 113 million people in the United States  between the ages of 18 and 44. And roughly 51% of this population is female. This means that of the women inside of the average man’s dating pool (based on age 18-44), more than 10% of them are actively or have been sugar babies in the past.'
        },
        {
            image: r2,
            content: 'Recreational Use Only',
            imgContent: dd2,
            explanation: "OnlyFans has seen rapid growth, with the platform boasting over 3 million content creators as of the latest reports. 43% of creators are based in the United States, and the surge in creator population, particularly during the COVID-19 pandemic, highlights the platform's role in the gig economy and its impact on how individuals monetize personal content."
        },
        {
            image: r3,
            content: 'They Belong To The Streets',
            explanation: 'Coming Soon'
        },
        {
            image: r4,
            content: 'How Many Services Records?',
            explanation: 'Coming Soon'
        },
    ];

    // const updateData = async (e) => {
    //     const collectionReference = polybase.collection("User");
    //     const recordData = await collectionReference
    //         .record("id") // contains id
    //         .call("setData", ['new name', 2]); // calling function for update and set the parameter

    //     console.log(recordData);
    //     await getData();
    // };

    // const deleteData = async (e) => {
    //     const collectionReference = polybase.collection("fax");
    //     const recordData = await collectionReference
    //         .record("1") // contains id
    //         .call("del"); // function for deleting data

    //     console.log(recordData);
    //     await getData();
    // };

    function isChainIdSepolia(chainId) {
        const targetChainId = Number(process.env.REACT_APP_CONTRACT_CHAIN);
        const chainIdString = chainId.toString();
        return chainIdString === targetChainId.toString();
    }

    const networkCheck = async (provider) => {
        let status;
        await provider.getNetwork().then((network) => {
            if (isChainIdSepolia(network.chainId)) {
                status = true;
            } else {
                status = false;
            }
        });

        if (status) {
            return true;
        } else {
            return false;
        }
    };

    const removalRequest = async (id) => {
        try {
            // payment function
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

            const isNetwork = await networkCheck(provider);
            if (!isNetwork) {
                alert(`This action is only for the ${process.env.REACT_APP_CONTRACT_CHAIN_NAME} network.`);
                return;
            }

            const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
            const contractABI = ABI;
            const contract = new ethers.Contract(contractAddress, contractABI, signer);

            setIsLoadTx(true);
            const tx = await contract.payForRemoval();
            await tx.wait().then(async () => {
                // delete data from database
                const collectionReference = polybase.collection("dbFax");
                await collectionReference
                    .record(id) // contains id
                    .call("del"); // function for deleting data

                setDataImage(null);
                setImageUploaded();
                setScore();
                setImageURL();
                setIDImage();
                setIsLoadTx(false)
                alert('Data has been successfully deleted');
            })
        } catch (error) {
            console.log(error);
            setIsLoadTx(false);
        }
    };

    function formatToThreeDecimals(num) {
        const numStr = num.toString();
        const index = numStr.indexOf('.');

        if (index === -1) {
            // If there is no decimal point, add it with three trailing zeros.
            return `${numStr}.000`;
        } else {
            const decimalPart = numStr.substring(index + 1);
            if (decimalPart.length >= 3) {
                // If there are more than three digits after the decimal, truncate.
                return numStr.substring(0, index + 4);
            } else {
                // If there are less than three, add the necessary number of zeros.
                return `${numStr}${'0'.repeat(3 - decimalPart.length)}`;
            }
        }
    }

    const getData = async (e) => {
        const collectionReference = polybase.collection("dbFax");
        // const record = await collectionReference.get(); // for getting all list
        const record = await collectionReference.record(IDImage).get();
        // console.log("yolo"); 
        // console.log(record.toJSON());

        const { data } = record;
        // console.log(data);
        setDataImage(data)
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getData();
        console.log(AllImageData);
    }, []);

    return (
        <div className='relative z-50 bg-main pt-4 h-screen overflow-x-hidden'>
            <div className='absolute rounded-full bg-second blur-ellipse1'></div>
            <div className='absolute rounded-full bg-second blur-ellipse2'></div>
            <Header />
            {IsLoadTx &&
                <div className="fixed bottom-0 right-0 m-5 z-50">
                    <div id="toast-default" class="flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow" role="alert">
                        <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-black rounded-lg">
                            <div role="status">
                                <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-400 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                            </div>
                        </div>
                        <div class="ml-3 text-sm font-bold">Waiting for transaction...</div>
                    </div>
                </div>
            }
            <div>
                <div className="relative text-center mt-10 md:mt-20 px-4 pb-4">
                    <h1 className="text-white font-black text-3xl lg:text-6xl 2xl:text-7xl font-TT">Report</h1>
                </div>

                <div className="text-center pb-4">
                    <div className="relative">
                        <img className="wave-position w-screen" style={{ zIndex: '-1' }} src={wave} alt="" />
                        <div className={`bg-white/5 backdrop-blur-sm rounded-xl border ${DataImage !== null ? "border-green-600" : "border-white"} mt-6 lg:mt-8 max-w-2xl mx-4 md:mx-auto`}>
                            <h1 className="text-base lg:text-2xl text-white mb-6 pt-5">304Fax Partner History Report Tm</h1>
                            <hr />
                            <div className="grid py-5 px-6 space-y-2">
                                {contentReport.map((item, index) => (
                                    <div>
                                        <div className="flex space-x-4">
                                            <div className={`${item.image === r3 ? 'px-3.5' : 'px-4'} ${DataImage?.CheckboxOptions[`Option${index + 1}`] ? 'border border-green-600' : ''} ${index === 3 && DataImage !== null && "border border-green-600"} bg-white/2 backdrop-blur-sm rounded-lg py-2`}>
                                                <div className="h-full flex items-center">
                                                    <img src={item.image} className="h-fit" alt="" />
                                                </div>
                                            </div>
                                            <div onClick={() => {
                                                if (SelectedItem === null) {
                                                    setSelectedItem(index)
                                                } else {
                                                    setSelectedItem(null)
                                                }
                                            }} className={`${DataImage?.CheckboxOptions[`Option${index + 1}`] ? 'border border-green-600' : ''} ${index === 3 && DataImage !== null && "border border-green-600"} cursor-pointer bg-white/2 backdrop-blur-sm rounded-lg w-full grid grid-cols-6 items-center px-4 text-white`}>
                                                <p className="text-base text-start col-span-5">{item.content}</p>
                                                {SelectedItem === index ?
                                                    <img className="ml-auto" src={arrowUp} alt="" />
                                                    :
                                                    <img className="ml-auto" src={arrowDown} alt="" />
                                                }
                                            </div>
                                        </div>
                                        {SelectedItem === index &&
                                            <>
                                                {index > 1 ?
                                                    <p className="my-2 text-white">{item.explanation}</p>
                                                    :
                                                    <div>
                                                        <p className="my-2 text-white">{item.explanation}</p>
                                                        <img className="ml-auto pb-2" src={item.imgContent} alt="" />
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="max-w-2xl mx-4 md:mx-auto mt-5">
                            <div className="grid grid-cols-4 text-white text-xs">
                                <p>0-0.2: Certain Match</p>
                                <p>0.2-0.4: Confident Match</p>
                                <p>0.4-0.6: Uncertain Match</p>
                                <p>0.6-0.8: Weak Match</p>
                            </div>
                        </div>
                        {ImageUploaded &&
                            <div>
                                <div className="max-w-2xl mx-4 md:mx-auto mt-5">
                                    <div className="w-full border rounded-xl bg-white/5 backdrop-blur-sm py-6">
                                        {IsScan &&
                                            <h1 className="text-white text-2xl pb-5 font-bold">{Score} Non-Match Index</h1>
                                        }
                                        <img className="mx-auto h-48 w-auto rounded-lg" src={ImageUploaded} alt="uploaded" />
                                    </div>
                                </div>
                                <div className="max-w-2xl mx-4 md:mx-auto mt-5">
                                    <div className="w-full border rounded-xl bg-white/5 backdrop-blur-sm py-6">
                                        <h1 className="text-white text-2xl pb-5 font-bold">Link</h1>
                                        <a href={`${ImageURL}`} target="_blank" rel="noreferrer" className="text-xl text-white text-extralight">{ImageURL}</a>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {ImageUploaded &&
                        <div className="mt-3">
                            <button onClick={() => { removalRequest(IDImage) }} className="text-white underline">This you? Submit removal request</button>
                        </div>
                    }
                    {IsScan &&
                        <div>
                            <div className="max-w-7xl mx-4 md:mx-auto mt-8">
                                <div className="grid grid-cols-4 text-white text-xs">
                                    <div className="flex items-center justify-center space-x-1.5">
                                        <p className="px-2 py-1 rounded-full bg-[#ff2702]">90 to 100</p>
                                        <p>Certain Match</p>
                                    </div>
                                    <div className="flex items-center justify-center space-x-1.5">
                                        <p className="px-2 py-1 rounded-full bg-[#fb930e]">83 to 89</p>
                                        <p>Confident Match</p>
                                    </div>
                                    <div className="flex items-center justify-center space-x-1.5">
                                        <p className="px-2 py-1 rounded-full bg-[#649d37]">70 to 82</p>
                                        <p>Uncertain Match</p>
                                    </div>
                                    <div className="flex items-center justify-center space-x-1.5">
                                        <p className="px-2 py-1 rounded-full bg-[#7d797c]">50 to 69</p>
                                        <p>Weak Match</p>
                                    </div>
                                </div>
                            </div>
                            <div className="max-w-7xl mx-4 md:mx-auto mt-5">
                                <div className="w-full border rounded-xl bg-white/5 backdrop-blur-sm p-6">
                                    <div className="grid grid-cols-4">
                                        {AllImageData.map((item, index) => (
                                            <div className="relative overflow-hidden">
                                                <div className="absolute max-w-fit w-full top-0 right-0 z-10">
                                                    <div className={`bg-white/50 backdrop-blur-sm rounded-full py-1 px-1.5 m-2 font-bold text-sm ${item?.distance <= 0.2 ? 'text-[#ff2702]' : item?.distance > 0.2 && item?.distance <= 0.4  ? 'text-[#fb930e]' : item?.distance > 0.4  && item?.distance <= 0.6 ? 'text-[#649d37]' : 'text-[#7d797c]'}`}>{item?.distance <= 0.001 ? 0 : formatToThreeDecimals(item?.distance)}</div>
                                                </div>
                                                <a href={item?.url} target="_blank">
                                                    <img className="w-full h-[400px] object-cover hover:scale-105 transition-transform duration-300" src={`https://skywalker.infura-ipfs.io/ipfs/${item?.label}`} />
                                                </a>
                                                <div className="absolute w-full bottom-0 left-0 z-10">
                                                    <div className="text-xs bg-white/20 py-4 backdrop-blur-sm underline">
                                                        <a href={item?.url} target="_blank">{item?.url}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <img className='ml-auto my-5' src={line2} alt='' />
            <img className='mb-3 mt-5 mb-10' src={line1} alt='' />
            <SocmedFooter />
        </div>
    );
}

export default Report;
