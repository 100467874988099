import { useState, useEffect, useRef, useContext } from "react";
import Header from "../components/header";
import link from '../assets/upload-bg.png';
import line1 from '../assets/line1.png';
import line2 from '../assets/line2.png';
import upload from '../assets/icon/upload.png'
import SocmedFooter from "../components/socmed-footer";
import * as faceapi from "face-api.js"
import { useNavigate } from "react-router-dom";
import { usePolybase } from "@polybase/react";
import { appContext } from "../context";
import moment from "moment";
import * as ethers from "ethers";
import ABI from '../assets/ABI_304fax.json'

function Deck() {
    

    return (
        <div className='relative z-50 bg-main pt-4 h-screen overflow-x-hidden'>
            <div className='absolute rounded-full bg-second blur-ellipse1'></div>
            <div className='absolute rounded-full bg-second blur-ellipse2'></div>
            <Header />

            <img className="wave-position w-screen" style={{ zIndex: '-1' }} src={link} alt="" />
            <div className="max-w-6xl mx-auto">
                <div className="relative text-center mt-10 md:mt-20 px-4 pb-4">
                    <h1 className="text-white font-black text-3xl lg:text-5xl 2xl:text-6xl font-TT">Deck</h1>
                    <iframe  style={{width: '1200px', maxWidth: '100%', height: '600px', color:"white"}} src="https://gamma.app/embed/fpsj1capkd3nv5f"  allow="fullscreen" title="304fax.ai"></iframe>
                </div>        
            </div>
            <img className='ml-auto my-5' src={line2} alt='' />
            <img className='mb-3 mt-5 mb-10' src={line1} alt='' />
            <SocmedFooter />
        </div>
    );
}

export default Deck;