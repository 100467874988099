import { useState, useEffect, useRef, useContext } from "react";
import Header from "../components/header";
import link from '../assets/upload-bg.png';
import line1 from '../assets/line1.png';
import line2 from '../assets/line2.png';
import upload from '../assets/icon/upload.png'
import SocmedFooter from "../components/socmed-footer";
import * as faceapi from "face-api.js"
import { useNavigate } from "react-router-dom";
import { usePolybase } from "@polybase/react";
import { appContext } from "../context";
import moment from "moment";
import * as ethers from "ethers";
import ABI from '../assets/ABI_304fax.json'

function CreateVideo() {
    

    return (
        // <div className='relative z-50 bg-main pt-4 h-screen overflow-x-hidden'>
        //     <div className='absolute rounded-full bg-second blur-ellipse1'></div>
        //     <div className='absolute rounded-full bg-second blur-ellipse2'></div>
        //     <Header />

        //     <img className="wave-position w-screen" style={{ zIndex: '-1' }} src={link} alt="" />
        //     <div className="max-w-6xl mx-auto">
        //         <div className="relative text-center mt-10 md:mt-20 px-4 pb-4">
        //             <h1 className="text-white font-black text-3xl lg:text-5xl 2xl:text-6xl font-TT">Are You an Influencer with 10k+ Followers?</h1>
        //             <p className="text-xl max-w-lg mx-auto mt-5 text-white">Get $100 USD in Bitcoin for your video showcasing 304fax.ai</p>
        //         </div>
        //         <div>
        //             <p className="text-xl max-w-lg mx-auto mt-5 text-white">We're seeking informative, and captivating content that highlights 304fax.ai's capabilities. Record a visual demonstration of 304fax.ai in action: Show a personal experience by searching for your own image on 304fax.ai, demonstrating its accuracy and ease of use. Make a fun short-form TikTok video or long-form YouTube how-to video.</p>
        //         </div>
        //         <div style={{color:"white"}}>
        //             <p>Step 1. Produce a Video and Publish it</p>
        //             <p>Step 2. Submit the Video URL</p>
        //             <p>Step 3. Get $100 USD in BTC</p>
        //             <p>We're excited to see your vision come to life! Once your video is published to your audience on YouTube or TikTok, simply submit the link below. Let the world see your creativity and our technology in action!</p>
        //         </div> <br></br>
        //         <p style={{color:"white"}}> Apply for Bitcoin Reward</p>
        //         <form className="influencer-form">
        //             <label htmlFor="email">Email address</label>
        //             <input id="email" type="email" placeholder="Enter your email" />
        //             <label htmlFor="video-url">Video URL</label>
        //             <input id="video-url" type="text" placeholder="Enter the video URL" />
        //             <label htmlFor="btc-address">Bitcoin Address</label>
        //             <input id="btc-address" type="text" placeholder="Enter your Bitcoin address" />
        //             <div className="checkbox-group">
        //                 <input id="agree" type="checkbox" />
        //                 <label htmlFor="agree">I have carefully read the qualifying criteria and agree with them.</label>
        //             </div>
        //             <button type="submit">Submit Video</button>
        //         </form>
                

    
                
        //         <div  className="mt-3 text-center">
        //             <h3>Qualifying Criteria</h3>
        //             <p>To ensure the success and integrity of our campaign, all video submissions must meet the following criteria:</p>
        //             <ul>
        //                 <li>Authentic Account: You must have a well-established YouTube or TikTok account with 10k+ genuine followers.</li>
        //                 <li>Must Have Hashtags: Video post must include these two hashtags #304fax #hoefax</li>
        //                 <li>English: Video must be in English.</li>
        //                 <li>Public: Video must be published and visible to your audience.</li>
        //                 <li>Relevant: Must focus on 304fax.ai highlighting its benefits and usability.</li>
        //                 <li>High-Quality: Must be high-resolution, well-produced video that conveys authenticity.</li>
        //                 <li>Ethical: Video must show ethical use of facial recognition and must respect privacy of others.</li>
        //                 <li>Clear: Video must include a clear demonstration of 304fax.ai in use, showcasing how it works.</li>
        //                 <li>Original: Must be original, newly made specifically for this campaign. Video must not be reposted.</li>
        //                 <li>One Per Influencer: You can submit only one unique video per 3 months.</li>
        //                 <li>Quality Audience: Video must be published to real and genuine audience.</li>
        //                 <li>Views: Video must have a minimum of 1000 views</li>
        //                 <li>Alignment: We reserve the right to discretion in rewarding only videos that we deem beneficial for our brand.</li>
        //             </ul>

        //         </div>
        //     </div>
        //     <img className='ml-auto my-5' src={line2} alt='' />
        //     <img className='mb-3 mt-5 mb-10' src={line1} alt='' />
        //     <SocmedFooter />
        // </div>
        
        <div className="bg-gray-800 text-white min-h-screen">
        {/* <div className='relative z-50 bg-main pt-4 h-screen overflow-x-hidden'></div> */}
            <div className='absolute rounded-full bg-second blur-ellipse1'></div>
            <div className='absolute rounded-full bg-second blur-ellipse2'></div>
            <Header /> 
            <img className="wave-position w-screen" style={{ zIndex: '-1' }} src={link} alt="" />
        <div className="container mx-auto px-4 py-12">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h1 className="text-5xl font-semibold">Are You an Influencer with 10K+ Followers?</h1>
              <p className="text-2xl my-6">Get $100 USD in Bitcoin for your video showcasing 304fax.Ai</p>
              <div>
                <p>We're seeking informative, and captivating content that highlights 304fax.Ai's capabilities. Record a visual demonstration of 304fax.Ai in action: Show a personal experience by searching for your own image on 304fax.Ai, demonstrating its accuracy and ease of use. Make a fun short-form TikTok video or long-form YouTube how-to video.</p>
                <div className="my-6">
                  <h2 className="text-3xl font-semibold">Step 1. Produce a Video and Publish it</h2>
                  <h2 className="text-3xl font-semibold">Step 2. Submit the Video URL</h2>
                  <h2 className="text-3xl font-semibold">Step 3. Get $100 USD in BTC</h2>
                </div>
                <p>We're excited to see your vision come to life! Once your video is published to your audience on YouTube or TikTok, simply submit the link below. Let the world see your creativity and our technology in action!</p>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-semibold">Apply for Bitcoin Reward</h2>
                <form className="mt-4">
                  <input type="email" placeholder="Email address" className="w-full p-2 mb-4 bg-gray-700 text-white" required />
                  <input type="url" placeholder="Video URL" className="w-full p-2 mb-4 bg-gray-700 text-white" required />
                  <input type="text" placeholder="Bitcoin Address" className="w-full p-2 mb-4 bg-gray-700 text-white" required />
                  <div className="flex items-center mb-4">
                    <input type="checkbox" id="terms" className="mr-2" required />
                    <label htmlFor="terms" className="text-sm">I have carefully read the qualifying criteria below and agree with them.</label>
                  </div>
                  <button type="submit" className="w-full p-2 bg-blue-600 hover:bg-blue-700">Submit Video</button>
                </form>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-semibold">Qualifying Criteria</h2>
                <ul className="list-disc ml-6 my-4">
                  <li>Authentic Account: You must have a well-established YouTube or TikTok account with 10k+ genuine followers.</li>
                  <li>Must Have Hashtags: Video post must include these two hashtags #reverseimagesearch #304fax</li>
                  <li>English: Video must be in English.</li>
                  <li>Publish: Video must be published and visible to your audience.</li>
                  <li>Relevant: Must focus on 304fax.ai highlighting its benefits and usability.</li>
                <li>High-Quality: Must be high-resolution, well-produced video that conveys authenticity.</li>
                <li>Ethical: Video must show ethical use of facial recognition and must respect privacy of others.</li>
                <li>Clear: Video must include a clear demonstration of 304fax.ai in use, showcasing how it works.</li>
                <li>Original: Must be original, newly made specifically for this campaign. Video must not be reposted.</li>
                <li>One Per Influencer: You can submit only one unique video per 3 months.</li>
                <li>Quality Audience: Video must be published to real and genuine audience.</li>
                <li>Views: Video must have a minimum of 1000 views</li>
                <li>Alignment: We reserve the right to discretion in rewarding only videos that we deem beneficial for our brand.</li>

                  
                </ul>
              </div>
            </div>
            {/* <div style={{ backgroundImage: "url('https://placehold.co/600x400?text=Wireframe+Face+Illustration')" }} className="bg-cover"></div> */}
          </div>
        </div>
            <img className='ml-auto my-5' src={line2} alt='' />
            <img className='mb-3 mt-5 mb-10' src={line1} alt='' />
            <SocmedFooter />
      </div>
        
    );
}

export default CreateVideo;