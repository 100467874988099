import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from '../assets/logo.png';
// import { Link } from "react-router-dom";

import r1 from '../assets/icon/r-1.png';
import r2 from '../assets/icon/r-2.png';
import r3 from '../assets/icon/r-3.png';
import r4 from '../assets/icon/r-4.png';
import dd1 from '../assets/SugarBabyPopula.jpeg';
import dd2 from '../assets/Onlyfans Creat.jpeg';
import arrowDown from '../assets/icon/arrow-down2.png';
import arrowUp from '../assets/icon/arrow-up.png';
import wave from '../assets/wave.png';
import line from '../assets/line1.png';
import How from '../components/how';
import Plan from '../components/plan';
import Tokenomics from '../components/tokenomics';
import Follow from '../components/follow';
import Testimonial from "../components/testimonial";
import About from "../components/about";

function Home() {
    const navigate = useNavigate();
    // const [navShow, setNavShow] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [SelectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        // Show the notification after a delay
        const notificationTimeout = setTimeout(() => {
            setIsVisible(true);
        }, 1000); // Adjust the delay as needed

        return () => {
            // Clear the timeout when the component unmounts
            clearTimeout(notificationTimeout);
        };
    }, []);

    const closeNotification = () => {
        setIsVisible(false);
    };

    const contentReport = [
        {
            image: r1,
            content: 'I’m Not Saying, They’re a Gold Digger, But....',
            imgContent: dd1,
            explanation: 'According to the U.S. Census, there are roughly 113 million people in the United States  between the ages of 18 and 44. And roughly 51% of this population is female. This means that of the women inside of the average man’s dating pool (based on age 18-44), more than 10% of them are actively or have been sugar babies in the past.'
        },
        {
            image: r2,
            content: 'Recreational Use Only',
            imgContent: dd2,
            explanation: "OnlyFans has seen rapid growth, with the platform boasting over 3 million content creators as of the latest reports. 43% of creators are based in the United States, and the surge in creator population, particularly during the COVID-19 pandemic, highlights the platform's role in the gig economy and its impact on how individuals monetize personal content."
        },
        {
            image: r3,
            content: 'They Belong To The Streets',
            explanation: 'Coming Soon'
        },
        {
            image: r4,
            content: 'How Many Services Records?',
            explanation: 'Coming Soon'
        },
    ];

    return (
        <div className='relative z-50 bg-main overflow-x-hidden'>
            {
                isVisible && (
                    <div className="w-full bg-second text-white p-4 shadow-md transform translate-y-0 opacity-100 transition-transform transition-opacity duration-300">
                        <div className="container mx-auto">
                            <div className="flex justify-between items-center">
                                {/* <p className="text-lg">This 100% a meme concept. There is no roadmap, no promises of features, functionality, or development</p> */}
                                <p className="text-lg">This is 100% a meme concept. There is no formal team, roadmap, promises of features, intrinsic value, nor expectation of financial return. Entertainment purposes only</p>
                                <button onClick={closeNotification} className="text-white">
                                    &times;
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            <div className='relative pt-4'>
                <div className='absolute rounded-full bg-second blur-ellipse1'></div>
                <div className='absolute rounded-full bg-second blur-ellipse2'></div>
                <header className="lg:py-4">
                    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <nav className="bg-white/5 backdrop-blur-sm border border-white relative flex items-center justify-between h-16 rounded-xl lg:h-16 lg:px-5 px-2 lg:mt-0">
                            <div className="w-full">
                                <a href="/#" title="" className="flex">
                                    <img className="w-auto h-6 lg:h-8" src={Logo} alt="" />
                                </a>
                            </div>

                            <div className="w-full text-end">
                                <button onClick={() => { navigate('/scan') }} className="bg-second text-white py-2 px-8 rounded-lg">Scan</button>
                            </div>
                        </nav>
                    </div>
                </header>
                <div className=''>
                    <div className="relative text-center mt-10 md:mt-20 px-4 pb-4">
                        <h1 className="text-white font-black text-3xl lg:text-6xl 2xl:text-7xl font-TT">Modern Dating Is Complex</h1>
                        <p className="text-white text-sm lg:text-xl mt-5">Vet a Potential Match & Discover Red Flags Early</p>
                        <div type="text" className="bg-white/5 backdrop-blur-sm border border-white px-4 py-2 rounded-3xl text-white max-w-fit mx-auto my-5">CA: 0xdfdgkdjkfek323432423424</div>
                        <div className="flex justify-center space-x-4 pt-2">
                            {/* <button className="bg-second px-6 py-2 rounded-lg text-white">Get 304Fax Report</button> */}
                            <a href="/scan" className="bg-second px-6 py-2 rounded-lg text-white no-underline" target="_blank" rel="noopener noreferrer">
                                Get 304Fax Report™️
                            </a>
                            <a href="/#" className="border border-white px-10 py-2 rounded-lg text-white">DEX</a>
                        </div>
                    </div>

                    <div className="text-center pb-4">
                        <div className="relative">
                            <img className="wave-position w-screen" style={{ zIndex: '-1' }} src={wave} alt="" />
                            <div className="bg-white/5 backdrop-blur-sm rounded-xl border border-white mt-6 lg:mt-8 max-w-2xl mx-4 md:mx-auto">
                                <h1 className="text-base lg:text-2xl text-white mb-6 pt-5">304Fax Partner History Report™️</h1>
                                <hr />
                                <div className="grid py-5 px-6 space-y-2">
                                    {contentReport.map((item, index) => (
                                        <div>
                                            <div className="flex space-x-4">
                                                <div className={`${item.image === r3 ? 'px-3.5' : 'px-4'} bg-white/2 backdrop-blur-sm rounded-lg py-2`}>
                                                    <div className="h-full flex items-center">
                                                        <img src={item.image} className="h-fit" alt="" />
                                                    </div>
                                                </div>
                                                <div onClick={() => {
                                                    if (SelectedItem === null) {
                                                        setSelectedItem(index)
                                                    } else {
                                                        setSelectedItem(null)
                                                    }
                                                }} className="cursor-pointer bg-white/2 backdrop-blur-sm rounded-lg w-full grid grid-cols-6 items-center px-4 text-white">
                                                    <p className="text-base text-start col-span-5">{item.content}</p>
                                                    {SelectedItem === index ?
                                                        <img className="ml-auto" src={arrowUp} alt="" />
                                                        :
                                                        <img className="ml-auto" src={arrowDown} alt="" />
                                                    }
                                                </div>
                                            </div>
                                            {SelectedItem === index &&
                                                <>
                                                    {index > 1 ?
                                                        <p className="my-2 text-white">{item.explanation}</p>
                                                        :
                                                        <div>
                                                            <p className="my-2 text-white">{item.explanation}</p>
                                                            <img className="ml-auto pb-2" src={item.imgContent} alt="" />
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="w-6/12 lg:w-auto mt-20 line-position" src={line} alt="" />
            </div>
            <About />
            <How />
            <Plan />
            <Tokenomics />
            <Follow />
            <Testimonial />
            <footer className='py-5 px-5 md:px-10 bg-white/5 backdrop-blur-sm border-t text-center text-white text-sm'>
                <div>
                    Copyright 2023. All Right Reserved.
                </div>
                {/* <div className="flex space-x-4">
                    <a href="https://t.me/+bWV-rSddSJdmODUx" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 496 512" fill="white"><path d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z" /></svg>
                    </a>
                    <a href="https://twitter.com/304fax" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 512 512" fill="white"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                    </a>
                </div> */}
            </footer>
        </div>
    );
}

export default Home;