import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home';
import Report from './pages/report';
import Scan from './pages/scan';
import Upload from './pages/upload';
import CreateVideo from './pages/createvideo';
import Deck from './pages/deck';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/report" element={<Report />} />
      <Route path="/scan" element={<Scan />} />
      <Route path="/upload" element={<Upload />} />
      <Route path="/createvideo" element={<CreateVideo />} />
      <Route path="/deck" element={<Deck />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
